import React from "react";
import { createRoot } from "react-dom/client";  // Correct import
import TextParallaxContentExample from "./components/TextParallaxContentExample";
import './index.css'; 
const app = document.getElementById("app");

if (app) {
  const root = createRoot(app);  // Only call createRoot if app exists
  root.render(<TextParallaxContentExample />);
} else {
  console.error("App element not found");
}