import React, { useRef, useState, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { FiArrowUpRight, FiMenu, FiX } from "react-icons/fi";

const TextParallaxContentExample = () => {
  return (
    <div className="bg-white">
      {/* Sticky Header with Gradient Button */}
      <HeaderWithGradientButton />

      {/* First Parallax Content */}
      <TextParallaxContent
        imgUrl="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?q=80&w=2671&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        subheading="Collaborate"
        heading="Built for all of us."
      >
        <ExampleContent />
      </TextParallaxContent>

      {/* Second Parallax Content */}
      <TextParallaxContent
        imgUrl="https://images.unsplash.com/photo-1530893609608-32a9af3aa95c?q=80&w=2564&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        subheading="Quality"
        heading="Never compromise."
      >
        <ExampleContent />
      </TextParallaxContent>

      {/* New Carousel Section with Videos */}
      <VideoCarousel />

      {/* Disappearing Features */}
      <DisappearingFeatures />

      {/* Second Carousel without Videos */}
      <CarouselWithoutVideos />

      {/* Footer */}
      <Footer />
    </div>
  );
};

// Sticky Header with Gradient Button
const HeaderWithGradientButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="sticky top-0 z-50 bg-white flex justify-between items-center px-8 py-4 shadow-md">
      {/* Logo with 20px margin left for mobile */}
      <div className="text-3xl font-bold text-red-600 ml-[20px] md:ml-0">
        <img
          src="https://pb360.me/wp-content/uploads/2024/09/cropped-Group-4014-1.webp"
          alt="logo"
          className="h-8 w-auto"
        />
      </div>

      {/* Desktop and Tablet Menu */}
      <div className="hidden md:flex space-x-8 items-center">
        <a href="#" className="text-lg font-semibold">
          Solutions
        </a>
        <a href="#" className="text-lg font-semibold">
          Resources
        </a>
        <a href="#" className="text-lg font-semibold">
          Pricing
        </a>
        <a href="#" className="text-lg font-semibold">
          FAQ
        </a>
      </div>

      {/* Gradient Button outside the drawer */}
      <div className="hidden md:block">
        <GradientShadowButton />
      </div>

      {/* Hamburger Menu for Mobile */}
      <div className="md:hidden flex items-center space-x-6">
        {/* Get started button with extra margin for mobile */}
        <div className="ml-[20px] sm:ml-[40px]">
          <GradientShadowButton small />
        </div>

        <motion.button
          whileTap={{ scale: 0.9 }}
          onClick={() => setIsOpen(true)}
          className="text-3xl p-2"
        >
          <FiMenu />
        </motion.button>
      </div>

      {/* Mobile Drawer */}
      <motion.nav
        className="fixed top-0 bottom-0 right-0 w-[240px] bg-white shadow-lg p-6"
        animate={isOpen ? "open" : "closed"}
        variants={navVariants}
        initial="closed"
      >
        <motion.button
          className="text-3xl absolute top-6 right-6"
          onClick={() => setIsOpen(false)}
        >
          <FiX />
        </motion.button>

        <motion.div className="mt-16 space-y-6 text-right" variants={linkWrapperVariants}>
          <motion.a variants={navLinkVariants} href="#" className="block text-lg font-semibold">
            Solutions
          </motion.a>
          <motion.a variants={navLinkVariants} href="#" className="block text-lg font-semibold">
            Resources
          </motion.a>
          <motion.a variants={navLinkVariants} href="#" className="block text-lg font-semibold">
            Pricing
          </motion.a>
          <motion.a variants={navLinkVariants} href="#" className="block text-lg font-semibold">
            FAQ
          </motion.a>
        </motion.div>
      </motion.nav>
    </header>
  );
};

// Variants for animations
const navVariants = {
  open: { x: 0, opacity: 1 },
  closed: { x: "100%", opacity: 0 },
};

const linkWrapperVariants = {
  open: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const navLinkVariants = {
  open: { x: 0, opacity: 1 },
  closed: { x: 25, opacity: 0 },
};

// Gradient Button Component
const GradientShadowButton = () => {
  return (
    <div className="group relative w-fit transition-transform duration-300 active:scale-95 mr-4 sm:mr-0">
      <button className="relative z-10 rounded-lg bg-gradient-to-br from-indigo-500 to-fuchsia-500 p-0.5 duration-300 group-hover:scale-110">
        <span className="block rounded-md bg-slate-950 px-4 py-2 font-semibold text-slate-100 duration-300 group-hover:bg-slate-950/50 group-hover:text-slate-50 group-active:bg-slate-950/80">
          Get started <FiArrowUpRight className="inline" />
        </span>
      </button>
      <span className="pointer-events-none absolute -inset-4 z-0 transform-gpu rounded-2xl bg-gradient-to-br from-indigo-500 to-fuchsia-500 opacity-30 blur-xl transition-all duration-300 group-hover:opacity-90 group-active:opacity-50" />
    </div>
  );
};

// Video Carousel Component
const VideoCarousel = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  return (
    <div className="relative w-full">
      <Gradient />
      <div ref={ref} className="relative z-0 flex flex-col gap-6 md:gap-12">
        <CarouselItem
          scrollYProgress={scrollYProgress}
          position={1}
          numItems={4}
          videoUrl="https://www.w3schools.com/html/mov_bbb.mp4" // Video for item 1
        />
        <CarouselItem
          scrollYProgress={scrollYProgress}
          position={2}
          numItems={4}
          videoUrl="https://www.w3schools.com/html/movie.mp4" // Video for item 2
        />
        <CarouselItem
          scrollYProgress={scrollYProgress}
          position={3}
          numItems={4}
          videoUrl="https://www.w3schools.com/html/mov_bbb.mp4" // Video for item 3
        />
        <CarouselItem
          scrollYProgress={scrollYProgress}
          position={4}
          numItems={4}
          videoUrl="https://www.w3schools.com/html/movie.mp4" // Video for item 4
        />
      </div>
      <Buffer />
    </div>
  );
};

const CarouselItem = ({ scrollYProgress, position, numItems, videoUrl }) => {
  const stepSize = 1 / numItems;
  const end = stepSize * position;
  const start = end - stepSize;

  const opacity = useTransform(scrollYProgress, [start, end], [1, 0]);
  const scale = useTransform(scrollYProgress, [start, end], [1, 0.05]);
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handlePlayPause = () => {
      if (videoElement) {
        if (scrollYProgress.get() >= start && scrollYProgress.get() < end) {
          videoElement.play().catch((error) => console.error("Auto-play failed:", error));
        } else {
          videoElement.pause();
        }
      }
    };

    const unsubscribe = scrollYProgress.onChange(handlePlayPause);

    return () => unsubscribe();
  }, [scrollYProgress, start, end]);

  return (
    <motion.div
      style={{
        opacity,
        scale,
      }}
      className="grid aspect-video w-full shrink-0 place-content-center rounded-2xl bg-neutral-900"
    >
      {videoUrl ? (
        <video
          ref={videoRef}
          src={videoUrl}
          controls
          className="w-full h-full rounded-2xl"
        />
      ) : (
        <span className="text-lg text-neutral-600">Feature demo here</span>
      )}
    </motion.div>
  );
};

// Second Carousel without Videos
const CarouselWithoutVideos = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  return (
    <div className="relative w-full">
      <Gradient />
      <div ref={ref} className="relative z-0 flex flex-col gap-6 md:gap-12">
        <CarouselItemNoVideo scrollYProgress={scrollYProgress} position={1} numItems={4} />
        <CarouselItemNoVideo scrollYProgress={scrollYProgress} position={2} numItems={4} />
        <CarouselItemNoVideo scrollYProgress={scrollYProgress} position={3} numItems={4} />
        <CarouselItemNoVideo scrollYProgress={scrollYProgress} position={4} numItems={4} />
      </div>
      <Buffer />
    </div>
  );
};

const CarouselItemNoVideo = ({ scrollYProgress, position, numItems }) => {
  const stepSize = 1 / numItems;
  const end = stepSize * position;
  const start = end - stepSize;

  const opacity = useTransform(scrollYProgress, [start, end], [1, 0]);
  const scale = useTransform(scrollYProgress, [start, end], [1, 0.05]);

  return (
    <motion.div
      style={{
        opacity,
        scale,
      }}
      className="grid aspect-video w-full shrink-0 place-content-center rounded-2xl bg-neutral-900"
    >
      <span className="text-lg text-neutral-600">Feature demo here</span>
    </motion.div>
  );
};

// Gradient Component
const Gradient = () => (
  <div className="sticky top-0 z-10 hidden h-24 w-full bg-gradient-to-b from-indigo-50 to-indigo-50/0 md:block" />
);

const Buffer = () => <div className="h-24 w-full md:h-48" />;

// Footer Component
const Footer = () => {
  return (
    <footer className="bg-gray-100 text-gray-600 py-12">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Logo and Copyright */}
        <div className="flex flex-col items-start">
          <span className="text-red-600 text-2xl font-bold">pb360.me</span>
          <p className="text-sm mt-4">© Copyright 2024, PB360. All rights reserved.</p>
          <p className="text-sm">"PB360®" is a registered service mark owned by Turquoise Choice.</p>
        </div>

        {/* Company Links */}
        <div>
          <h4 className="font-bold mb-4">Company</h4>
          <ul className="space-y-2">
            <li>
              <a href="#" className="hover:text-red-600">About Us</a>
            </li>
            <li>
              <a href="#" className="hover:text-red-600">Contact Us</a>
            </li>
            <li>
              <a href="#" className="hover:text-red-600">Work With Us</a>
            </li>
          </ul>
        </div>

        {/* Solutions Links */}
        <div>
          <h4 className="font-bold mb-4">Our Solutions</h4>
          <ul className="space-y-2">
            <li>
              <a href="#" className="hover:text-red-600">Single Event</a>
            </li>
            <li>
              <a href="#" className="hover:text-red-600">Tournaments</a>
            </li>
            <li>
              <a href="#" className="hover:text-red-600">League</a>
            </li>
          </ul>
        </div>

        {/* Social Media Links */}
        <div>
          <h4 className="font-bold mb-4">Follow Us</h4>
          <div className="flex space-x-4">
            <a href="#" className="hover:text-red-600"><i className="fab fa-facebook-f"></i> Facebook</a>
            <a href="#" className="hover:text-red-600"><i className="fab fa-twitter"></i> X</a>
            <a href="#" className="hover:text-red-600"><i className="fab fa-instagram"></i> Instagram</a>
            <a href="#" className="hover:text-red-600"><i className="fab fa-linkedin-in"></i> LinkedIn</a>
            <a href="#" className="hover:text-red-600"><i className="fab fa-youtube"></i> YouTube</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

// Rest of the components (TextParallaxContent, StickyImage, OverlayCopy, ExampleContent, DisappearingFeatures, etc.)

const IMG_PADDING = 12;

const TextParallaxContent = ({ imgUrl, subheading, heading, children }) => {
  return (
    <div style={{ paddingLeft: IMG_PADDING, paddingRight: IMG_PADDING }}>
      <div className="relative h-[150vh]">
        <StickyImage imgUrl={imgUrl} />
        <OverlayCopy heading={heading} subheading={subheading} />
      </div>
      {children}
    </div>
  );
};

const StickyImage = ({ imgUrl }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);

  return (
    <motion.div
      style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: `calc(100vh - ${IMG_PADDING * 2}px)`,
        top: IMG_PADDING,
        scale,
      }}
      ref={targetRef}
      className="sticky z-0 overflow-hidden rounded-3xl"
    >
      <motion.div className="absolute inset-0 bg-neutral-950/70" style={{ opacity }} />
    </motion.div>
  );
};

const OverlayCopy = ({ subheading, heading }) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [250, -250]);
  const opacity = useTransform(scrollYProgress, [0.25, 0.5, 0.75], [0, 1, 0]);

  return (
    <motion.div
      style={{ y, opacity }}
      ref={targetRef}
      className="absolute left-0 top-0 flex h-screen w-full flex-col items-center justify-center text-white"
    >
      <p className="mb-2 text-center text-xl md:mb-4 md:text-3xl">{subheading}</p>
      <p className="text-center text-4xl font-bold md:text-7xl">{heading}</p>
    </motion.div>
  );
};

const ExampleContent = () => (
  <div className="mx-auto grid max-w-5xl grid-cols-1 gap-8 px-4 pb-24 pt-12 md:grid-cols-12">
    <h2 className="col-span-1 text-3xl font-bold md:col-span-4">
      Additional content explaining the above card here
    </h2>
    <div className="col-span-1 md:col-span-8">
      <p className="mb-4 text-xl text-neutral-600 md:text-2xl">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi,
        blanditiis soluta eius quam modi aliquam quaerat odit deleniti minima
        maiores voluptate est ut saepe accusantium maxime doloremque nulla
        consectetur possimus.
      </p>
      <p className="mb-8 text-xl text-neutral-600 md:text-2xl">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium
        reiciendis blanditiis aliquam aut fugit sint.
      </p>
      <button className="w-full rounded bg-neutral-900 px-9 py-4 text-xl text-white transition-colors hover:bg-neutral-700 md:w-fit">
        Learn more <FiArrowUpRight className="inline" />
      </button>
    </div>
  </div>
);

// Disappearing Features Component
const DisappearingFeatures = () => {
  return (
    <>
      <div className="relative h-fit bg-indigo-50">
        <Features />
      </div>
    </>
  );
};

const Features = () => {
  return (
    <div className="relative mx-auto grid h-full w-full max-w-7xl grid-cols-1 gap-8 px-4 md:grid-cols-2">
      <Copy />
      <CarouselWithoutVideos />
    </div>
  );
};

const Copy = () => {
  return (
    <div className="flex h-fit w-full flex-col justify-center py-12 md:sticky md:top-0 md:h-screen">
      <span className="w-fit rounded-full bg-indigo-500 px-4 py-2 text-sm uppercase text-indigo-100">
        Lorem ipsum dolor
      </span>
      <h2 className="mb-4 mt-2 text-5xl font-medium leading-tight">
        Learn and grow with Lorem Ipsum Dolor
      </h2>
      <p className="text-lg text-indigo-950">
        Lorem ipsum dolor sit amet consectetur. Dolor quis a leo lobortis orci
        tortor eget. Enim proin aliquam nulla a lacus pellentesque quam in.
        Nec vel vel nulla nunc vel in molestie proin convallis.
      </p>
    </div>
  );
};

export default TextParallaxContentExample;
